
import Vue from 'vue';
import { subscriptionProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { BillingSubscription } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/subscription';
import dateFormat from '@/utils/dateFormat';

export default Vue.extend({
  name: 'PaymentCancelPage',

  computed: {
    subscriptionProvider(): any {
      return subscriptionProvider(this.$store);
    },

    subscription(): BillingSubscription {
      return this.subscriptionProvider.getSubscription;
    },

    canceledSubsctiptionDateString(): string {
      return this.subscription.valid_until
        ? dateFormat(this.subscription.valid_until, 'MMMM DD, YYYY')
        : 'Invalid Date';
    },

    checkSubscriptionInvalid(): boolean {
      const validUntil = this.subscription.valid_until;
      if (validUntil) {
        return new Date(validUntil).valueOf() < Date.now();
      }
      return false;
    }
  }
});
